import { Component, Mixins } from 'vue-property-decorator';
import Layout from '@/components/layouts/main.vue';
import PageTitle from '@/components/ui/page-title/pageTitle.vue';
import { Action, Getter } from 'vuex-class';
import { ListResponseType } from '@/types/response/list-response.type';
import FilterMixin from '@/mixins/filter.mixin';
import GridTable from '@/components/ui/grid-table/gridTable.vue';
import ActionsCellRenderer from '@/components/ui/grid-table/cell/actions-cell-renderer/actionsCellRenderer.vue';
import { GridTableApi, GridTableOptions, GridTablePaginationParams } from '@/components/ui/grid-table/gridTable';
import BoxHeader from '@/components/ui/box-header/boxHeader.vue';
import { ActionsCellRendererParams } from '@/components/ui/grid-table/cell/actions-cell-renderer/actionsCellRenderer';
import { UserListRequest } from '@/types/request/auth-request.types';
import { UserType } from '@/types/api/user/user.type';
import { getRoleLabel, getRolesAsArray } from '@/helpers/permission.helper';
import UpdateUserModal from '@/components/features/modals/update-user-modal/update-user-modal.vue';
import { MODAL_CONFIG } from '@/helpers/app.helper';
import { USER_STATUS_ACTIVE, USER_STATUS_BLOCKED, USER_STATUS_NON_CONFIRMED } from '@/constants/user-status.constants';

const title = 'users.title';

@Component({
  name: 'Users',
  page: {
    title
  },
  components: {
    Layout,
    PageTitle,
    GridTable,
    ActionsCellRenderer,
    BoxHeader,
  },
})
export default class Users extends Mixins(FilterMixin) {
  title = title;
  table: GridTableApi | null = null;
  tableOptions: GridTableOptions = {
    pagination: {
      callback: this.gridChangeCallback
    }
  };
  filter: any = this.initFilter();

  @Action('getUsers', { namespace: 'users' })
  getUsers!: (params?: UserListRequest) => Promise<ListResponseType<UserType>>;

  @Getter('getUsers', { namespace: 'users' })
  users!: UserType;

  @Getter('getCurrentUser', { namespace: 'auth' })
  currentUser!: UserType;

  @Action('blockUser', { namespace: 'users' })
  blockUser!: (id: number) => Promise<UserType>;

  @Action('unblockUser', { namespace: 'users' })
  unblockUser!: (id: number) => Promise<UserType>;

  mounted() {
    this.table = this.$refs.usersTable as any;
  }

  initFilter() {
    return {
      email: this.$route.query.email || null,
      role: this.$route.query.role || null,
    };
  }

  refreshTable() {
    if (this.table) {
      this.table.refresh();
    }
  }

  gridChangeCallback(params: GridTablePaginationParams) {
    return this.getUsers({
      page: params.pageNumber,
      limit: params.pageLimit,
      ...this.filter,
    });
  }

  get tableFields() {
    return [
      {
        label: 'ID',
        key: 'id',
      },
      {
        label: this.$t('users.name'),
        key: 'name',
      },
      {
        label: this.$t('users.email'),
        key: 'email',
      },
      {
        label: this.$t('users.role'),
        key: 'role',
        formatter: (value: number) => getRoleLabel(value)
      },
      {
        label: '',
        key: 'actions',
        thStyle: { width: '40px' },
      },
    ];
  }

  getActionsParams(data): ActionsCellRendererParams<UserType> {
    return {
      data: data.item,
      buttons: [
        {
          type: 'edit',
          actionCallback: this.onEditAction,
          btnContent: '<i class="fe-edit" />',
          show: () => data.item.id !== this.currentUser.id
        },
        {
          type: 'block',
          actionCallback: this.onBlockAction,
          btnContent: '<i class="fe-lock" />',
          show: () => [
            USER_STATUS_NON_CONFIRMED,
            USER_STATUS_ACTIVE
          ]
            .includes(data.item.status) && data.item.id !== this.currentUser.id
        },
        {
          type: 'unblock',
          actionCallback: this.onUnblockAction,
          btnContent: '<i class="fe-unlock" />',
          show: () => data.item.status === USER_STATUS_BLOCKED && data.item.id !== this.currentUser.id
        },
      ]
    };
  }

  onEditAction(params: ActionsCellRendererParams<UserType>) {
    this.$modal.show(UpdateUserModal, {
      user: params.data,
      updateCallback: () => {
        this.refreshTable();
      }
    }, MODAL_CONFIG);
  }

  onBlockAction(params: ActionsCellRendererParams<UserType>) {
    this.blockUser(params.data.id).then(() => {
      this.refreshTable();
    })
  }

  onUnblockAction(params: ActionsCellRendererParams<UserType>) {
    this.unblockUser(params.data.id).then(() => {
      this.refreshTable();
    })
  }

  onAddNew() {
    this.$modal.show(UpdateUserModal, {
      updateCallback: () => {
        this.refreshTable();
      }
    }, MODAL_CONFIG);
  }

  get roleOptions() {
    return [
      { text: this.$t('users.all'), value: null },
      ...getRolesAsArray()
    ];
  }
}
