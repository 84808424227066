import { Vue, Component, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { ValidationObserver } from 'vee-validate';
import BTextInputValidation from '@/components/ui/inputs/b-text-input-validation/bTextInputValidation.vue';
import BSelectInputValidation from '@/components/ui/inputs/b-select-input-validation/bSelectInputValidation.vue';
import { showToast } from '@/helpers/toast.helper';
import { getRoleLabel, getRolesAsArray, ROLE_PARTNER } from '@/helpers/permission.helper';
import { UserCard } from '@/store/modules/users/actions';
import { UserType } from '@/types/api/user/user.type';
import { UserCreateRequest, UserUpdateRequest } from '@/types/request/auth-request.types';

@Component({
  name: 'UpdateUserModal',
  components: {
    ValidationObserver,
    BTextInputValidation,
    BSelectInputValidation,
  },
})
export default class UpdateUserModal extends Vue {
  tryingToUpdate = false;
  name = '';
  email = '';
  password = '';
  repeatPassword = '';
  role = ROLE_PARTNER;

  @Prop({
    required: true
  })
  updateCallback!: (response: any) => any;

  @Prop({
    required: false
  })
  user?: UserType;

  @Action('createUser', { namespace: 'users' })
  createUser!: (params: UserCreateRequest) => Promise<UserType>;

  @Action('updateUser', { namespace: 'users' })
  updateUser!: (data: UserCard<UserUpdateRequest>) => Promise<UserType>;

  @Getter('getCurrentUser', { namespace: 'auth' })
  currentUser!: UserType;

  created() {
    if (this.user) {
      this.name = this.user.name;
      this.role = this.user.role;
    }
  }

  cancel() {
    this.$emit('close');
  }

  getRoleLabel(role: number) {
    return getRoleLabel(role);
  }

  get roleOptions() {
    return getRolesAsArray();
  }

  onApply() {
    if (!this.tryingToUpdate) {
      this.tryingToUpdate = true;
      const params: any = {
        name: this.name,
        role: this.role,
        ...(this.user ? {} : { password: this.password }),
        ...(this.user ? {} : { email: this.email }),
      };
      const action = this.user
        ? this.updateUser({ id: this.user.id, params })
        : this.createUser(params);

      action
        .then(response => {
          this.tryingToUpdate = false;
          this.updateCallback(response);
          this.cancel();
          const message = this.user ? 'users.successUpdate' : 'users.successCreate';
          showToast(this.$t(message) as any);
        })
        .catch(() => {
          this.tryingToUpdate = false;
          const message = this.user ? 'users.errorUpdate' : 'users.errorCreate';
          showToast(this.$t(message) as any);
        });
    }
  }
}
