import { i18n } from '@/i18n';

export const USER_STATUS_NON_CONFIRMED = 1;
export const USER_STATUS_ACTIVE = 2;
export const USER_STATUS_BLOCKED = 3;

export const getUserStatus = (type: number) => {
  switch (type) {
    case USER_STATUS_NON_CONFIRMED:
      return i18n.t('userStatus.nonConfirmed');
    case USER_STATUS_ACTIVE:
      return i18n.t('userStatus.active');
    case USER_STATUS_BLOCKED:
      return i18n.t('userStatus.blocked');
    default:
      return '';
  }
}

export const getUserStatusList = () => [
  { value: USER_STATUS_NON_CONFIRMED, text: getUserStatus(USER_STATUS_NON_CONFIRMED) },
  { value: USER_STATUS_ACTIVE, text: getUserStatus(USER_STATUS_ACTIVE) },
  { value: USER_STATUS_BLOCKED, text: getUserStatus(USER_STATUS_BLOCKED) },
]
